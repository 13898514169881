import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  menus = [
    {title: "Home", route: "home", isActive: true, icon: "house", displayTitle: false},
    {title: "Resume", route: "resume", isActive: true, icon: "briefcase", displayTitle: true},
     {title: "Projects", route: "projects", isActive: true,  icon: "desktop", displayTitle: true},
     {title: "Videos", route: "videos", isActive: false,  icon: "brands fa-youtube", displayTitle: true},
    {title: "Contact Me", route: "contact", isActive: true,  icon: "envelope", displayTitle: true}
  ];
  currentMenu: any = this.menus[0];
  loaded: boolean = false;

  constructor(private router: Router){
   
    console.log("RoiuterL ", this.router);

    this.router.events.subscribe(x=>{
      this.loaded = false;
        if(x instanceof NavigationEnd){
          this.menus.forEach(y=>{
            if(("/"+ y.route) == x.url){
              this.currentMenu = y;
              this.loaded = true;
              return;
            }
          })
        }
      this.loaded = true;
   
    })
  }

  ngOnInit() {
    AOS.init();
  }

  openNav(){
    var elem = document.getElementsByClassName("header-div-container")[0];

    // if(e){
    //   elem.classList.remove("col-1");
    //   elem.classList.add("col-12");
    //   return;
    // }
    // elem.classList.remove("col-12");
    // elem.classList.add("col-1");
  }



 

  
}
