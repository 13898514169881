import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-icon',
  templateUrl: './loading-icon.component.html',
  styleUrls: ['./loading-icon.component.scss']
})
export class LoadingIconComponent implements OnInit {

  @Input() size = 50;
  @Input() text: string = "";
  @Input() loadingDots : boolean = true;
  @Input() loaderColor: string = "#41b3cc"
  @Input() textColor: string = "black"

  constructor() { }

  ngOnInit() {
  }

}
