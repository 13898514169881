import { Component, HostListener, Injectable, OnInit } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-screen-size',
  templateUrl: './screen-size.component.html',
  styleUrls: ['./screen-size.component.scss']
})
export class ScreenSizeComponent implements OnInit {

  private extra_large_min = 1200;
  private large_max = 1199;
  private large_min = 992;
  private medium_max = 991;
  private medium_min = 768;
  private small_max = 767;
  private small_min = 576;
  private extra_small_max = 575;

  private innerWidth: any;

  constructor() { }

  getScreenSize(){
    this.innerWidth = window.innerWidth;

    if(this.innerWidth <= this.extra_small_max) return "xs";
    if(this.innerWidth >= this.small_min && this.innerWidth <= this.small_max) return "sm";
    if(this.innerWidth >= this.medium_min && this.innerWidth <= this.medium_max) return "md";
    if(this.innerWidth >= this.large_min && this.innerWidth <= this.large_max) return "lg";
    if(this.innerWidth >= this.extra_large_min) return "xl";
  }

  isMobileSize(): boolean{
    return ["xs", "sm"].indexOf(this.getScreenSize()) >= 0;
  }

  isDesktopSize(): boolean{
    return ["md", "lg", "xl"].indexOf(this.getScreenSize()) >= 0;
  }

  ngOnInit() {
  }

}
