import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './view/body/homepage/homepage.component';
import { HeaderComponent } from './view/header/header.component';
import { FooterComponent } from './view/footer/footer.component';
import { ResumeComponent } from './view/body/resume/resume.component';
import { ContactComponent } from './view/body/contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProjectsComponent } from './view/body/projects/projects.component';
import { HttpClientModule } from '@angular/common/http';
import { LoadingIconComponent } from './view/shared/loading-icon/loading-icon.component';
import { SuccessIconComponent } from './view/shared/success-icon/success-icon.component';
import { FailIconComponent } from './view/shared/fail-icon/fail-icon.component';
import { SideNavComponent } from './view/header/side-nav/side-nav.component';
import { ScreenSizeComponent } from './view/shared/screen-size/screen-size.component';
import { HelperComponent } from './view/shared/helper/helper.component';
import { VideosComponent } from './view/body/videos/videos.component';
import { FormSuccessFailIconsComponent } from './view/shared/form-success-fail-icons/form-success-fail-icons.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    FooterComponent,
    ResumeComponent,
    ContactComponent,
    ProjectsComponent,
    LoadingIconComponent,
    SuccessIconComponent,
    FailIconComponent,
    SideNavComponent,
    ScreenSizeComponent,
    HelperComponent,
    VideosComponent,
    FormSuccessFailIconsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
