import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { iBaseDataContext } from '../interfaces/iBaseDataContext';
import { BaseDataContextService } from '../data-context-services/base-data-context.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService implements iBaseDataContext {

  private baseDataContextService: BaseDataContextService;
  public table_name:string
 
  constructor(private httpClient: HttpClient) {
    this.baseDataContextService = new BaseDataContextService(this.httpClient);
  }

  getAll(condition?: any, columns? : string[], extraCondition?: string, limit?:number, orderBy?:{col: string, dir: string} | {col:string, dir: string}[], fetchFrom?: any): Observable<any>{
    this.baseDataContextService.table_name = this.table_name;
    return this.baseDataContextService.getAll(condition, columns, extraCondition, limit, orderBy, fetchFrom);
  }


  getSingle(condition?: any, columns?: string[], extraCondition?: string, limit?: number, orderBy?: { col: string; dir: string; } | { col: string; dir: string; }[], fetchFrom?: string | string[]): Observable<any> {
    this.baseDataContextService.table_name = this.table_name;
    return this.baseDataContextService.getSingle(condition, columns, extraCondition, limit, orderBy, fetchFrom);
  }

  getById(id: number = null): Observable<any>{
    this.baseDataContextService.table_name = this.table_name;
    return this.baseDataContextService.getById(id);
  }

  getLatestRecord(): Observable<any>{
    this.baseDataContextService.table_name = this.table_name;
   return this.baseDataContextService.getLatestRecord();
  }


  getAllOrderBy(col: string, dir:string = "ASC"): Observable<any>{
    this.baseDataContextService.table_name = this.table_name;
    return this.baseDataContextService.getAllOrderBy(col, dir);
  }

  getAllOrderByMultiple(orderBys: {col:string, dir:string}[]): Observable<any>{
    this.baseDataContextService.table_name = this.table_name;
    return this.baseDataContextService.getAllOrderByMultiple(orderBys);
  }

  getAndFetchFrom(fetchFroms: string[]): Observable<any>{
    this.baseDataContextService.table_name = this.table_name;
    return this.baseDataContextService.getAndFetchFrom(fetchFroms);
  }

  /**
   * Global function to add an object to defined table.
   * The query property that holds the value is addObject
   * 
   * @param table_name Name of table to which data will be added
   * @param params The object to add, should be a defined class 
   * @returns Resultset
   */
  add(params: any): Observable<any>{
    this.baseDataContextService.table_name = this.table_name;
    return this.baseDataContextService.add(params);
  }

  delete(params: any): Observable<any>{
    this.baseDataContextService.table_name = this.table_name;
    return this.baseDataContextService.delete(params);
  }

  update(params: any): Observable<any>{
    this.baseDataContextService.table_name = this.table_name;
    return this.baseDataContextService.update(params);
  }
}
