import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/controller/services/base.service';
import { HelperComponent } from '../../shared/helper/helper.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

    projects: any[];

  constructor(private baseService: BaseService, private helper: HelperComponent) {
    this.projects = [];
   }

  async ngOnInit() {
    await this.getProjects();
    this.helper.scrollToTop();
  }

  async getProjects(){
    this.baseService.table_name = "demos";
    this.projects = await this.baseService.getAll().toPromise();
   }

   selectDemo(x){
    
   }

}
