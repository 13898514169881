import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'header-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @Input() newNotificationsCount;

  showLanguageList: boolean = false;
  languages: string[] = [];
  public menus = [];

  constructor() {
   }

  ngOnInit() {
    this.languages = ["English", "Français- French", "Español - Spanish", "عربى - Arabic"];
    this.menus = 
    [
      { id: 1, name: "Home", route: "/home", isRouteMenu: true, isActive: true},
     
      { id: 3, name: "Start Your Website", route: "/quote-application", isRouteMenu: true, isActive:true},
      {id: 9, name: "View Sites", route: "/sites", isRouteMenu: true, isActive: true},
      {id: 8, name: "FAQS", route: "/faqs", isRouteMenu: true, isActive: true},
      { id: 2, name: "Contact us", isRouteMenu: false, isActive: true},  
      // {id: 11, name: "Notifications", isRouteMenu: false, isActive: false},
     
      
    ];

    this.updateNavbar();
  }

  private updateNavbar(){
    //  View sites
    //if(environment.viewSites) this.menus.push( {id: 9, name: "View Sites", route: "/sites", isRouteMenu: true, isActive: true},)

  }



  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.body.style.overflowY = ""
    this.showLanguageList = false;
  }

}
