import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailDataContextService } from '../data-context-services/email-data-context.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private dataContext: EmailDataContextService
  ) { }

  send(from: string, to: string, subject: string, message:string): Observable<any>{
    const response = this.dataContext.send(from, to, subject, message);
    return response;
  }
}
