import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { iBaseDataContext } from '../interfaces/iBaseDataContext';

@Injectable({
  providedIn: 'root'
})

export class BaseDataContextService implements iBaseDataContext {
 
  table_name: string;
  
  constructor(private httpClient: HttpClient) {
   }

   /**
    * Generic Endpoint to get all objects, conditions are optional
    * @param conditionalObject 
    * @returns A list of object
    */
    getAll(condition?: any, columns? : string[], extraCondition?: string, limit?:number, orderBy?:{col: string, dir: string} | {col:string, dir: string}[], fetchFrom?: {table: string, objectify: boolean} | {table: string, objectify: boolean}[]): Observable<any>{
      if(limit == null) limit = 999;
      const routerName: string = environment.serviceUrl + "/"+this.table_name+"/";
      const response = this.httpClient.get(routerName + "getAll", {
        params: {
          condition: JSON.stringify(condition),
          columns : JSON.stringify(columns),
          extraCondition : JSON.stringify(extraCondition),
          limit : (limit !== undefined)? limit.toString() : "999", 
          orderBy : JSON.stringify(orderBy), 
          fetchFroms : JSON.stringify({fetchFroms: fetchFrom})
        }});
      return response;
    }
   
   /**
    * Generic Endpoiint to get a single object
    * @param conditionalObject 
    * @returns A single object
    */
    getSingle(condition?: any, columns?: string[], extraCondition?: string, limit?: number, orderBy?: { col: string; dir: string; } | { col: string; dir: string; }[], fetchFrom?: any): Observable<any> {
      if(limit == null) limit = 999;
      const routerName: string = environment.serviceUrl + "/"+this.table_name+"/";
      const response = this.httpClient.get(routerName + "getSingle", {
        params: {
          condition: JSON.stringify(condition),
          columns : JSON.stringify(columns),
          extraCondition : JSON.stringify(extraCondition),
          limit : (limit !== undefined)? limit.toString() : "999", 
          orderBy : JSON.stringify(orderBy), 
          fetchFrom : JSON.stringify({fetchFroms: fetchFrom})
        }});
      return response;
    }
   
  /**
   * Generic Endpoint to get an object by ID
   * @param id 
   * @returns A single object
   */
    getById(id: number): Observable<any>{
      const routerName: string = environment.serviceUrl + "/"+this.table_name+"/";
      const response = this.httpClient.get(routerName + "getById", {
        params: {
          id:id.toString()
        }
      });
      return response;
    }

  /**
   * Generic Endpoint to get the latest record from a table
   * @returns A single object
   */
    getLatestRecord(): Observable<any>{
      const routerName: string = environment.serviceUrl + "/"+this.table_name+"/";
      const response = this.httpClient.get(routerName + "getLatestRecord");
      return response;
    }

  /**
   * Generic Endpoint to get all objects based on a specific order
   * @param column Column name
   * @param direction Either ASC or DESC
   * @returns A list of objects
   */
    getAllOrderBy(col: string, dir:string = "ASC"): Observable<any>{
      const routerName: string = environment.serviceUrl + "/"+this.table_name+"/";
      const response = this.httpClient.get(routerName + "getAllOrderBy", {
        params: {
          col: col, 
          dir: dir
        }
      });
      return response;
    }

  /**
   * Generic Endpoint to get all objects based on multiple columns and their directions
   * @param columns An array of columns and their directions
   * @returns A list of object
   */
    getAllOrderByMultiple(orderBys: {col:string, dir:string}[]): Observable<any>{
      const routerName: string = environment.serviceUrl + "/"+this.table_name+"/";
      const response = this.httpClient.get(routerName + "getAllOrderByMultiple", {
        params: { 
          orderBys:  JSON.stringify(orderBys)
        }
      });

      return response;
    }

    getAndFetchFrom(fetchFroms: string[]): Observable<any>{
      const routerName: string = environment.serviceUrl + "/"+this.table_name+"/";
      const response = this.httpClient.get(routerName + "getAndFetchFrom", {
        params: {
          fetchFroms: JSON.stringify(fetchFroms)
        }
      });
      return response;
    }

  /**
   * Generic Endpoint to add an object to defined table.
   * The query property that holds the value is addObject
   * 
   * @param table_name Name of table to which data will be added
   * @param params The object to add, should be a defined class 
   * @returns Resultset
   */
  add(params: any): Observable<any>{
    console.log("ADDING: ", params);
    console.log("ADDING TO TABLE: ", this.table_name);
    const routerName: string = environment.serviceUrl + "/"+this.table_name+"/";
    const response = this.httpClient.post(routerName + "add", {params: {addObject: JSON.stringify(params)}});
    return response;
  }

    /**
   * Generic Endpoint to update an object from a defined table
   * @param params 
   * @returns 
   */
    update(params: any): Observable<any>{
      const routerName: string = environment.serviceUrl + "/"+this.table_name+"/";
      const response = this.httpClient.get(routerName + "update", {params: {updateObject: JSON.stringify(params)}});
      return response;
    }

  /**
   * Generic Endpoint to delete an object from a defined table
   * @param params 
   * @returns
   */
  delete(params: any): Observable<any>{
    
    const routerName: string = environment.serviceUrl + "/"+this.table_name+"/";
    const response = this.httpClient.get(routerName + "delete", {params: {deleteObject: JSON.stringify(params)}});
    return response;
  }

}
