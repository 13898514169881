import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-helper',
  templateUrl: './helper.component.html',
  styleUrls: ['./helper.component.scss']
})
export class HelperComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goTo(route:string, navType:string = 'self'){
    window.open(
      ((route.indexOf("www") >=0) || route.indexOf("https") >= 0) ? route : this.router.serializeUrl(this.router.createUrlTree([route])),
       `_${navType}`
    );
    
  }

  
  scrollToTop(){
     window.scrollTo({ top: 0, behavior: 'auto' });
  }
}
