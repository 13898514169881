import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmailService } from 'src/app/controller/services/email.service';
import { HelperComponent } from '../../shared/helper/helper.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  form: FormGroup;
  sending: boolean;
  sendingStatus: string;
  providedServices: any[] = [];

  constructor(private formBuilder: FormBuilder, private emailService: EmailService, public helper: HelperComponent) {
    this.sending = false;
    this.sendingStatus = "";
   }

  ngOnInit() {
    this.initProvidedServices();
    this.initForm();
    
    this.helper.scrollToTop();
  }

  ngOnChanges(){
    this.refreshForm();
  }


  private initForm(){
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      organization: [''],
      message: ['', [Validators.required]],
      providedServices: [this.providedServices[0], [Validators.required]]
    });
  }

  private initProvidedServices(){
    this.providedServices = [ "Website Development", "Software Development", "Bug fixing", "Typing", "Something else"];
  }


  refreshForm(){
    if(this.form !== undefined) this.form.reset();
    this.sending = false;
    this.sendingStatus = "";
   
  }

  async submit(){
    
    try{
      this.helper.scrollToTop();
      this.sending = true;

      const sentMessage = 
      `Name: ${this.form.get("name").value} <br/>` +
      `Organization: ${this.form.get("organization").value } <br/>` +
      `Required Service: ${this.form.get("providedServices").value } <br/><br/>`+
      `Message: <br/>` +
      `${this.form.get("message").value}`;

      let emailResponse = await this.emailService.send(
        this.form.get("email").value, 
        "support" , 
        "Contacting via portfolio", 
        sentMessage
      ).toPromise();
      
      this.sendingStatus = emailResponse ? "success" : "fail"; 
    }
 
    catch(error){
      this.sendingStatus = "fail";
      console.error("Error when sending contact form: ", error);
    }

  }

  openEmailApp(){
    window.open(`mailto:nasser.adam2@live.com?subject=&body=${this.form.get('message').value}`);
  }
}
