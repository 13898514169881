import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ScreenSizeComponent } from '../shared/screen-size/screen-size.component';
import { HelperComponent } from '../shared/helper/helper.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menus: any[];
  displayMenu:boolean = false;
  @Output() menuClickedEvent = new EventEmitter<boolean>();
  

  constructor(public screenSize: ScreenSizeComponent, public helper: HelperComponent) {
   }

  @HostListener('window:resize', ['$event'])
  onResize(){
    if(this.screenSize.isDesktopSize()) this.displayMenu = false; 
    this.menuClickedEvent.emit(this.displayMenu);
  }

  ngOnInit() {
    this.initMenus(); 
  }

  private initMenus(){
    this.menus = [
      {title: "Home", route: "home", isActive: true, icon: "house", displayTitle: false},
      {title: "Resume", route: "resume", isActive: true, icon: "briefcase", displayTitle: true},
       {title: "Projects", route: "projects", isActive: true,  icon: "desktop", displayTitle: true},
       {title: "Videos", route: "videos", isActive: false,  icon: "brands fa-youtube", displayTitle: true},
      {title: "Contact Me", route: "contact", isActive: true,  icon: "envelope", displayTitle: true}
    ];
  }

  openNav(){
    this.displayMenu = !this.displayMenu;
    this.menuClickedEvent.emit(this.displayMenu);
    
  }

  scrollToTop(){
    window.scrollTo(0, 0);
  }

}
