import { Component, OnInit } from '@angular/core';
import { ScreenSizeComponent } from '../../shared/screen-size/screen-size.component';
import { HelperComponent } from '../../shared/helper/helper.component';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {
  resume: any;
  showDescriptionWorkExp : number[] = [];

  constructor(private screenSize: ScreenSizeComponent, private helper: HelperComponent) { }

  ngOnInit() {
    this.initResume();
  }

  ngAfterViewInit(){
    // if(this.screenSize.isDesktopSize()){
    //   document.getElementById("workExp_11").click();
    //   document.getElementById("workExp_12").click();
    // }

    this.helper.scrollToTop();
    
  }

  private initResume(){
    this.resume = {
      workExperience: [
        new WorkExperience(
          11, 
          "Senior Software Developer", 
          {name: "Loftware", link: "https://www.linkedin.com/company/loftware", location: "Reading, England, United Kingdom"},
          "Nov 2022 - Present",
          "Full-Time/ Hybrid",
          `Being part of the UI UX team, my role consists of upgrading one of Loftware systems currently built in Knockout JS, into Angular. With some base codes already present, I implement the additional pages into TypeScript, HTML, Cypress (for testing) and also Kendo. With the latter being new to me, training was needed. Working in an Agile environment, I have frequent meetings with the team, which is multi national such as working closely with people from Slovenia, to raise any concerns and provide new ideas, and also the whole project is broken into 2-weeks sprints. While i am working into what is dubbed as the "Angular" project, there is still the old system which is present, known as the legacy application. So knowledge at how things work on the legacy app, and also knowledge of the whole system including designing labels, is paramount. With both the Angular app and the legacy app using the same back-end service which is in C#, experience on this language is important also. Working closely with the "legacy app" team, we get to share knowledge among ourselves and have Knowledge-sharing sessions. 

          <br/>As the work is broken into sprints, frequent pushed to a virtual machine is done, with builds being done through Jenkins. 
          
          <br/><br/>Front-End: Angular CLI, AngularJS, TypeScript, Kendo UI, JavaScript, CSS, SCSS, Debugging
          <br/>Back-End: C# .NET, unit testing, SQL Scripts
          <br/>Misc: GIT, Azure DevOps, Knockout JS, NPM, Cypress, SQL Server Management Studio, Jenkins`
        ),
        
        new WorkExperience(
          12,
          "Software Developer",
          {name: "Global Aerospace", link: "https://www.linkedin.com/company/global-aerospace", location: "London, United Kingdom"},
          "Jan 2018 - Nov 2022",
          "Full-Time/ Hybrid",
          `Responsible for the continuous development of mobile-oriented web projects, having bi-weekly sprints with the whole team which consists of developers, business analysts and testers. Constant communication during tasks’ implementations. Code pushed to Team Foundation server where sprints are broken into user stories, bugs-tracking are made easy, pull requests reviewed and created. Once a 2-week sprint completed, a release build is created for that sprint and deployed via Octopus where it is pushed to different server environments which each have distinct users. Environments are System Test, UAT and Live. Converted AngularJS project into an Angular CLI project. Knowledge on the different IT systems of the company is important since most systems use the same data for processing.

          <br/><br/>Front-End: Angular CLI, AngularJS, TypeScript, JavaScript, CSS, SCSS, Debugging, Bootstrap
          <br/>Back-End: C# .NET, unit testing, LINQ, SQL Scripts, Stored Procedures
          <br/>Misc: GIT, TFS, NPM, Team City, Gulp, Octopus, Cypress, Compodoc, SQL Server Management Studio, Queue Explorer`
        ),
        new WorkExperience(
          13,
          "Software Developer",
          {name:"MBA Group Ltd", link: "https://www.linkedin.com/company/mba-group-ltd", location: "Tottenham, London, United Kingdom"},
          "Jan 2016 - Oct 2017",
          "Full-Time/ On-site",
          `
          Building .NET applications, portals and landing pages. AJAX used to communicate client side to server side. Responsible at publishing codes live on the production servers. Experience at debugging and using Team Foundation Server for checking in/out codes. Working in a planned and comprehensive way. Bootstrap used to make portals mobile-oriented. Responsible for bugs fixing.
          <br/><br/>Front-End: HTML, CSS, JavaScript, JQuery, Bootstrap, AngularJS, AJAX
          <br/>Back-End: C# .NET, unit testing, ASP .NET, SQL Server Management Studio
          `
        ),
        new WorkExperience(
          14,
          "Software Developer/ Analyst",
          {name: "Astek Mauritius", link: "https://www.linkedin.com/company/astek-mauritius/", location: "Ebene Cybercity, Mauritius"},
          "Jun 2011 - Dec 2013",
          "Full-Time/ On-site",
          `
          Working on different IT projects, from internal projects using MVC3 structure to client projects using Java and C# .NET. Worked with other developers from Lyon, France so communication was important. Experience as a support to developers for an IOS project using Objective -C. Worked on the front-end side of multiple projects.
          <br/><br/>Front-End: HTML, HTML5, CSS, CSS3, JavaScript, AJAX, Bootstrap, Objective C, Selenium
          <br/>Back-End: C# .NET, JAVA
          `
        )
      ],

      education:[
        {
          id:21,
          institution: { name: "University of Hertfordshire", link: "https://www.linkedin.com/company/13121/", location: "Hatfield, UK"},
          degree: "Master of Science(MSc), Computer Software Engineering",
          date: "2014 - 2015",
          description: `
Grade: First Class (with Honors)Grade: First Class (with Honors)<br/>
Studying the following field:<br/>
- Java, C#<br/>
- C++<br/>
- Advanced Databases (R.Algebra, Normalisation, Entity Diagrams, etc..)<br/>
- Professional Issues (Dealing with Laws in Computer Science (DPA, CMA, etc..))<br/>
- Investigative Methods (Statistics, Analysis)<br/>
- Software Eng. Theory (Models: Waterfall, Agile, etc..)
          `
        },

        {
          id:22,
          institution: { name: "University of Mauritius", link: "https://www.linkedin.com/school/university-of-mauritius", location: "Reduit, Mauritius"},
          degree: "BSc Information Systems",
          date: "2008-2012",
          description: null
        }
      ]
    }
  }

  showHideDescription(id){
    const index = this.showDescriptionWorkExp.indexOf(id);
    if (index > -1) this.showDescriptionWorkExp.splice(index, 1); 
    else this.showDescriptionWorkExp.push(id);
  }

}

class WorkExperience{
  id: number;
  title:string;
  company: {name: string, link: string, location:string};
  date: string;
  type: string;
  description:string;

  constructor(id:number, title:string, company: {name: string, link: string, location:string}, date:string, type:string, description:string){
    this.id = id;
    this.title = title;
    this.company = company;
    this.date = date;
    this.type = type;
    this.description = description;
  }

}
