import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-success-fail-icons',
  templateUrl: './form-success-fail-icons.component.html',
  styleUrls: ['./form-success-fail-icons.component.scss']
})
export class FormSuccessFailIconsComponent implements OnInit {
  @Input() componentElement: any;

  constructor() { }

  ngOnInit() {
  }

}
