import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailDataContextService {

  routerName: string = environment.serviceUrl + "/email/";

  constructor(private httpClient: HttpClient) { }

  send(from: string, to: string, subject: string, message:string): Observable<any> {
    const emailOptions = {
      from: from,
      to: to,
      subject: subject,
      message: message,
     
    }
    return this.httpClient.post<any>(this.routerName + "send", { params: {otherObject: JSON.stringify(emailOptions)}});
  }
}
