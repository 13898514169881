import { Component, OnInit } from '@angular/core';
import { HelperComponent } from '../../shared/helper/helper.component';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  menus: any[];
  homepageIcons: any[];

  constructor(public helper: HelperComponent) { }

  ngOnInit() {
    this.initMenus();
    this.initHomepageIcons();
    this.helper.scrollToTop();
  }

  private initMenus(){
    this.menus = [
      {title: "Resume", route: "resume"},
      {title: "Projects", route: "resume"},
      {title: "Learning", route: "resume"},
      {title: "Cool stuffs", route: "resume"},
      {title: "Contact", route: "contact"}
    ]
  }

  private initHomepageIcons(){
    // this.homepageIcons = [
    //   new HomepageIcon("LinkedIn", "brands fa-linkedin-in", "https://www.linkedin.com/in/nasser-adam-3a396a81/"),
    //   new HomepageIcon("Github", "code-branch", "https://github.com/figAndOlive"),
    //   new HomepageIcon("Facebook", "brands fa-facebook-f", "https://www.facebook.com/ibexwebsolutions")

    // ]

    this.homepageIcons = [
      new HomepageIcon("Projects", "desktop", "projects"),
      new HomepageIcon("Videos", "brands fa-youtube", "videos"),
      new HomepageIcon("Contact Me", "envelope", "contact")

    ]

  }
}

class HomepageIcon{
  name: string;
  icon: string;
  url: string;

  constructor(name:string, icon: string, url: string){
    this.name = name;
    this.icon = icon;
    this.url = url;
  }
  
}


