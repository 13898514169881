import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './view/body/homepage/homepage.component';
import { ResumeComponent } from './view/body/resume/resume.component';
import { ContactComponent } from './view/body/contact/contact.component';
import { ProjectsComponent } from './view/body/projects/projects.component';
import { VideosComponent } from './view/body/videos/videos.component';


const routes: Routes = [
  { 
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path:'home', component: HomepageComponent },
  { path:'resume', component: ResumeComponent },
  { path:'projects', component: ProjectsComponent },
  { path:'videos', component: VideosComponent },
  { path:'contact', component: ContactComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
